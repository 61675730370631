import {store} from './store'
import {checkRequired} from './checkRequired'
import {finalize} from './finalize'
import {firebase} from '../../utilities'
import DB from '../../DAL/DB'
import {setLoading, setProject} from '../action_creators'
import {scoreProject} from './scoreProject'
import {auth} from '../../Auth'
import conditionGroupValidation from '../Field/utils'

const projects = new DB.Projects()

class ValidationError {
  constructor(errors) {
    this.errors = errors
    this.name = `Validation Error`
  }
}

export async function saveIntake(finish = false) {
  const state = store.getState()

  // the loading property is only modified by this function
  //  so if it's true, another invocation is active
  //  this conditional block basically serves as a lock
  if (state.status.loading) {
    const unsubscribe = store.subscribe(async () => {
      const state = store.getState()
      if (!state.status.loading) {
        unsubscribe()
        await saveIntake(finish)
      }
    })

    // Super important to prevent the automatic complete
    return
  }

  store.dispatch(setLoading(true))
  const {project, sections, fields} = state

  for (let i in sections) {
    const section = sections[i]
    section.fields = (section.order || []).map((fId) => fields[fId]).filter((j) => j)
  }

  project.intake.sections = sections

  if (finish) {
    console.log(`attempting to finalize this project; error checking`)

    const errors = checkRequired(project.intake)
    if (Object.keys(errors).length > 0) {
      store.dispatch(setLoading(false))
      throw new ValidationError(errors)
    }
  }

  project.photos = fields[project.id].images || {}

  console.info('saveIntake - currentUser:', auth.currentUser)
  try {
    if (finish) {
      console.log('do we come hree 2')
      await finalize(project)
      switch (project.status) {
        case `Review`:
          console.log('do we come hree')
          project.status = `Completed`
          project.completed_on = firebase.firestore.Timestamp.now()
          project.completed_by = {
            id: auth.currentUser.uid,
            name: auth.currentUser.displayName || auth.currentUser.email,
          }
          project.intakeResult = await scoreProject(project)
          project.reviewer = {
            name: auth.sunkaizenUser.name,
            id: auth.sunkaizenUser.id,
            email: auth.sunkaizenUser.email,
            type: auth.sunkaizenUser.type,
          }

          console.info('intakeResult:', project.intakeResult)
          if (!project.intakeResult) {
            alert('Sorry, failed to score the project. Please contact the support!')
            return
          }
          break
        case `In Progress`:
          project.status = `Review`
          break
        case `Needs Corrections`:
          project.status = `Review`
          break
        default:
          break
      }
    }

    const findQuestionValue = (qid) => {
      const sections = project.intake.sections
      let questionValue = ''
      if(questionValue && sections && Object.keys(sections).length > 0) {
        Object.keys(sections).forEach(rec => {
          const {fields} = sections[rec]
          const fieldRec = fields.find(x => x.id === qid)
          if(fieldRec) {
            questionValue = fieldRec.value
          }
        })
      }
      return questionValue
    }

    const { questions } = project && project.rubric || {questions: {}}

    if(questions && Object.keys(questions).length > 0) {
      let canAddAction = false;
      Object.keys(questions).forEach(rec => {
        if(questions[rec].OptionActions && Object.keys(questions[rec].OptionActions).length > 0) {
          const questionValue = findQuestionValue(rec)
          Object.keys(questions[rec].OptionActions).forEach((oA,indexA) => {
            questions[rec].OptionActions[oA].forEach(optionAction => {
                const { conditionGroup, actionIndex, strategy } = optionAction
              if (conditionGroup) {
                const { operand, cgList } = conditionGroup
                let isDisplay = conditionGroupValidation(cgList, fields, operand)
                canAddAction = isDisplay ? true : false;
              }
              if (!conditionGroup) {
                canAddAction = true;
              }
              if (canAddAction) {
                if (questions[rec].selectedActions && questions[rec].selectedActions[oA]) {
                  questions[rec].selectedActions[oA].push(`${actionIndex} - ${strategy}`)
                } else {
                  questions[rec].selectedActions = {
                    [oA]: []
                  }
                  questions[rec].selectedActions[oA].push(`${actionIndex} - ${strategy}`)
                }
              }
            })
          })
        } else {
            questions[rec].selectedActions = {}
        }
      })
    }

    project.updated = firebase.firestore.Timestamp.now()
    await projects.update(project.id, null, project)
    store.dispatch(setLoading(false))

    if (!finish) store.dispatch(setProject(project))
  } catch (e) {
    console.log(e)
    throw e
  }
}

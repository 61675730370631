import React, {useState} from 'react'
import {Button, Popconfirm} from 'antd'
import {dropdownData} from './actionsDataJSON'
import {SelectControlAction, InputCtrlAction, MultiInputControlAction} from './components/index'

// User can create actions. New Tab called Actions in form creation
const AddAction = ({
  action,
  handleDeleteActionCancel,
  handleDeleteActionConfirmation,
  handleDeleteAction,
  handleUpdateAction,
  currentIndex,
}) => {
  const [tags, setTags] = useState(action.tags)
  const tagInfoObj = {
    id: '',
    text: '',
    isEdit: true,
    isDelete: false,
  }

  const handleAddTags = () => {
    const tagsUpdated = [...tags]
    const tagInfoObjUpdated = {...tagInfoObj}

    tagInfoObjUpdated.id = tagsUpdated.length + 1
    tagInfoObjUpdated.text = ''
    tagInfoObjUpdated.isEdit = true
    tagInfoObjUpdated.isDelete = false

    tagsUpdated.push(tagInfoObjUpdated)
    setTags(tagsUpdated)

    const actionUpdated = {...action}
    actionUpdated.tags = tagsUpdated
    handleUpdateAction(actionUpdated)
  }

  const handleTagsUpdate = (e, obj) => {
    const editTags = [...tags]
    if (e.target.value) {
      const editedRec = editTags.find((x) => x.id === obj.id)
      editedRec.text = e.target.value
      editedRec.isEdit = false
      setTags(editTags)
    }

    const actionUpdated = {...action}
    actionUpdated.tags = editTags
    handleUpdateAction(actionUpdated)
  }

  const handleMaintainceUpdate = (e, obj) => {
    const editTags = [...tags]

    const editedRec = editTags.find((x) => x.id === obj.id)
    editedRec.maintaince = e.target.value
    setTags(editTags)

    const actionUpdated = {...action}
    actionUpdated.tags = editTags
    handleUpdateAction(actionUpdated)
  }

  const handleEditTags = (obj) => {
    const editTags = [...tags]
    const editedRec = editTags.find((x) => x.id === obj.id)
    editedRec.isEdit = true
    setTags(editTags)

    const actionUpdated = {...action}
    actionUpdated.tags = editTags
    handleUpdateAction(actionUpdated)
  }

  const handleDeleteTags = (obj) => {
    const editTags = [...tags]
    const editedRec = editTags.find((x) => x.id === obj.id)
    editedRec.isDelete = 'confirmation'
    setTags(editTags)

    const actionUpdated = {...action}
    actionUpdated.tags = editTags
    handleUpdateAction(actionUpdated)
  }

  const handleDeleteCancelTags = (obj) => {
    const editTags = [...tags]
    const editedRec = editTags.find((x) => x.isDelete === 'confirmation')
    editedRec.isDelete = false
    setTags(editTags)

    const actionUpdated = {...action}
    actionUpdated.tags = editTags
    handleUpdateAction(actionUpdated)
  }

  const handleDeleteConfirmation = (obj) => {
    const editTags = [...tags]
    const editedRec = editTags.find((x) => x.isDelete === 'confirmation')
    editedRec.isDelete = true
    setTags(editTags)

    const actionUpdated = {...action}
    actionUpdated.tags = editTags
    handleUpdateAction(actionUpdated)
  }

  const handleStrategyUpdate = (e) => {
    const actionUpdated = {...action}
    actionUpdated.strategy = e.target.value
    handleUpdateAction(actionUpdated)
  }

  const handleActionDescriptionUpdate = (e) => {
    const actionUpdated = {...action}
    actionUpdated.actionDescription = e.target.value
    handleUpdateAction(actionUpdated)
  }

  const handleReferenceInformationUpdate = (e) => {
    const actionUpdated = {...action}
    actionUpdated.referenceInformation = e.target.value
    handleUpdateAction(actionUpdated)
  }

  const handleOnChangeRating = (value, opt) => {
    const property = opt.dbProp
    const actionUpdated = {...action}
    actionUpdated[property] = value
    handleUpdateAction(actionUpdated)
  }

  return (
    <React.Fragment>
      <div className="addAction">
        <div className="addActionBg">
          <h4>Action #{currentIndex}</h4>
          <div className="actionStrategy">
            <InputCtrlAction
              name={'Strategy'}
              uniqueValue={action.id}
              defaultValue={action.strategy}
              handleChange={(e) => handleStrategyUpdate(e, 'strategy')}
            />
          </div>
        </div>

        <MultiInputControlAction
          name={'Action Description'}
          uniqueValue={action.id}
          defaultValue={action.actionDescription}
          handleChange={(e) => handleActionDescriptionUpdate(e)}
          isReq={true}
        />
        <MultiInputControlAction
          name={'Reference Information'}
          uniqueValue={action.id}
          defaultValue={action.referenceInformation}
          shortNotes={'[Google](www.google.com) | [Yahoo](http://yahoo.com)'}
          handleChange={(e) => handleReferenceInformationUpdate(e)}
        />

        <div className="actionDropdown">
          <div className="actionDropdownDisplay">
            {dropdownData.map((ddD, index) => (
              <SelectControlAction
                key={`SelectAction-${ddD.name}-${index}-${ddD.unquieProp}`}
                name={ddD.name}
                options={ddD.options}
                uniqueValue={action[ddD.unquieProp]}
                defaultValue={action[ddD.dbProp]}
                handleChange={(e) => handleOnChangeRating(e, ddD)}
              />
            ))}
          </div>

          <div className="actionTags">
            <div>
              <label className="padding-right-30 actionTagsLabel" htmlFor="tags">
                Tags{' '}
              </label>
              <Button className="actionTagsButton" title="add-tag" icon="plus" onClick={() => handleAddTags()} />
            </div>

            {(tags || [])
              .filter((x) => x.isDelete !== true)
              .map((x) => (
                <div key={`tags-${x.id}`} className="actionTagsAdd">
                  <Button
                    className="actionTagEdit"
                    title="edit"
                    icon={'edit'}
                    onClick={() => handleEditTags(x)}
                    data-cy="toggleEditFormBtn"
                  />
                  {x.isEdit ? (
                    <input
                      className="ant-input ant-input-lg actionTagName"
                      key={`tags-${tags.length}-${x.id}-${x.text}`}
                      onBlur={(e) => handleTagsUpdate(e, x)}
                      placeholder={`please enter Tag name`}
                      type="text"
                      id="actionName"
                      name="actionName"
                      defaultValue={x.text}
                    />
                  ) : (
                    <label className="actionTagName">{x.text}</label>
                  )}

                  <Popconfirm
                    onCancel={handleDeleteCancelTags}
                    onConfirm={handleDeleteConfirmation}
                    title="Are you sure you want to delete tag?"
                  >
                    <Button
                      className="actionTagNameDelete"
                      title="delete"
                      icon={'delete'}
                      onClick={() => handleDeleteTags(x)}
                      data-cy="toggledeleteFormBtn"
                    />
                  </Popconfirm>
                  <div className="actionCommonClass">
                    {' '}
                    <textarea
                      cols="39"
                      key={`tags-${tags.length}-${x.id}-${x.maintaince}`}
                      className="actionTagDesc"
                      placeholder={`please enter Tag description`}
                      onBlur={(e) => handleMaintainceUpdate(e, x)}
                      type="text"
                      id="maintaince"
                      defaultValue={x.maintaince}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="actionDelete">
          <Popconfirm
            onCancel={() => handleDeleteActionCancel(action)}
            onConfirm={() => handleDeleteActionConfirmation(action)}
            title="Are you sure you want to delete Action?"
          >
            <Button
              className="actionDeleteConf"
              title="deleteAction"
              icon={'delete'}
              onClick={() => handleDeleteAction(action)}
              data-cy="toggledeleteActionFormBtn"
            />
          </Popconfirm>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddAction

import {store} from '../utilities'
import stampMetadata from './stampMetadata'
import saveFields from './saveFields'
import DB from '../../DAL/DB'

export default async function save() {
  const state = store.getState()
  const {
    form,
    sections,
    actionsState: {actionList},
  } = state
  const batch = new DB.FirebaseBatch()

  if (!form || !form.order || form.order.length < 1) return
  const forms = new DB.Forms()
  const formSection = new DB.FormSection(form.id)
  const formAction = new DB.FormAction(form.id)
  stampMetadata(form)

  ;(actionList || []).forEach((act, index) => {
    batch.update(formAction, act.id, act)
  })

  form.order.forEach((id) => {
    const section = sections[id]

    if (section && section.id) {
      saveFields(section, form.id, batch)
      batch.update(formSection, section.id, section)
    } else {
      console.log('section for ID does not exist', section)
      // throw new Error("section for ID does not exist", id)
    }
  })

  batch.update(forms, form.id, form)

  try {
    await batch.commit()
  } catch (e) {
    throw new Error('something went wrong committing batch: ', e)
  }
}

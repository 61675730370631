import React, {useState} from 'react'

import {List, Button, Select, Icon, DatePicker} from 'antd'

function StyleWrapper({name, type, children, addInstance}) {
  return (
    <List.Item className="flex-row align-center justify-space-around">
      <div clasName="bold padding-left-2" style={{alignSelf: 'center', flex: 2, textAlign: `left`}}>
        {name}
      </div>
      <div className="flex-column" style={{flex: 5}}>
        {children}
      </div>
      <Button style={{alignSelf: 'center', margin: `0 0 0 1rem`}} onClick={() => addInstance(type)}>
        <Icon type="plus" />
      </Button>
      <div style={{flex: 1}}></div>
    </List.Item>
  )
}

export const DropdownItem = ({item, setFilter, deleteInstance, addInstance}) => (
  <StyleWrapper addInstance={addInstance} {...item}>
    {item.instances.map((value, i) => (
      <div key={i} className="flex-row" style={{padding: `0.5rem 0 0.5rem 0`}}>
        <Select
          value={value}
          style={{width: `100%`, padding: `0 5rem 0 0`}}
          onChange={(e) => setFilter(item.type, i, e)}
        >
          <Select.Option value="all">All</Select.Option>
          {item.options.map((option, i) => (
            <Select.Option value={typeof option == 'string' ? option : option.id} key={i}>
              {typeof option == 'string' ? option : option.name}
            </Select.Option>
          ))}
        </Select>
        <Button onClick={() => deleteInstance(item.type, i)}>
          <Icon type="minus" />
        </Button>
      </div>
    ))}
  </StyleWrapper>
)

export const DateItem = ({item, setFilter, deleteInstance, addInstance}) => {
  const [{before, after}] = item.instances || {}
  const setF = (bound, m, index) => {
    setFilter(item.type, index, {...{before, after}, [bound]: m})
  }

  return (
    <StyleWrapper addInstance={addInstance} {...item}>
      {item.instances.map((value, i) => (
        <div key={i} className="flex-row padding-top-0-5 padding-bottom-0-5">
          <div className="flex-row full-width" style={{padding: `0 5rem 0 0`}}>
            <DatePicker value={after} onChange={(e) => setF(`after`, e, i)} style={{flex: 1, margin: `0 1rem 0 0`}} />
            <DatePicker value={before} onChange={(e) => setF(`before`, e, i)} style={{flex: 1}} />
          </div>
          <Button onClick={() => deleteInstance(item.type, i)}>
            <Icon type="minus" />
          </Button>
        </div>
      ))}
    </StyleWrapper>
  )
}

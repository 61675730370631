import React from 'react'
import {Checkbox} from 'antd'

export default function Required({id, field, modify}) {
  return (
    <div className="flex-row padding-top-2 padding-bottom-2">
      <Checkbox
        onChange={(e) => modify({key: id, value: {...field, required: e.target.checked}})}
        value={field.required}
        checked={field.required}
        style={{marginRight: '1rem'}}
        data-cy="required"
      >
        Required
      </Checkbox>
      <Checkbox
        onChange={(e) => modify({key: id, value: {...field, photoRequired: e.target.checked}})}
        value={field.photoRequired}
        checked={field.photoRequired}
        style={{marginRight: '1rem'}}
        data-cy="requirePhoto"
      >
        Require Uploading Photos
      </Checkbox>
      {/* <Checkbox
                onChange={e => modify({id, value: {...field, requiredForFieldIntake: e.target.checked} })}
                value={field.requiredForFieldIntake}
                checked={field.requiredForFieldIntake}
            >
                Required for Field Intake
            </Checkbox> */}
    </div>
  )
}

import React, {useEffect, useState} from 'react'
import InspectorBrief from './InspectorBrief'
import {Icon, Select} from 'antd'
import {auth} from '../../Auth'
import {subscribe} from '../../HOC/Firebase'
import DB from '../../DAL/DB'

const projects = new DB.Projects()

export function InspectionDate({project, makeSubscription, subscribeResources}) {
  const [inspectors, setInspectors] = useState([])
  useEffect(
    function () {
      // inspector is not a 'client'
      if (project.inspection && project.inspection.inspector && project.inspection.inspector.type) return

      // gVzl9wV6Dmh2HKwNm4Eo2DrxSth2
      console.log(auth.sunkaizenUser.organization.id)

      return makeSubscription(
        {
          paths: [{collection: 'users'}],
          options: {
            where: [
              {field: 'organization.id', test: '==', value: auth.sunkaizenUser.organization.id},
              {field: 'type', test: '==', value: 'field-user'},
            ],
          },
        },
        'users'
      )
    },
    [project.inspection && project.inspection.inspector]
  )

  useEffect(
    function () {
      setInspectors(subscribeResources.users || [])
      console.log((subscribeResources.users || []).map((u) => u.id))
    },
    [subscribeResources]
  )

  async function onChange(index) {
    const inspector = inspectors[index]
    try {
      await projects.update(project.id, null, {inspection: {...project.inspection, inspector}})
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div
      className="full-width flex-column padding-bottom-1 margin-bottom-1"
      style={{flex: 3, height: '40%', border: '0.1rem 0 0.1rem 0 solid #C4CDD5'}}
    >
      {project.inspection.date && project.inspection.date.toDate && (
        <div>
          <h4 style={{padding: '1.5rem', fontWeight: 'bold'}}>
            <Icon className="padding-right-1" type="calendar" />
            {project.inspection.date.toDate().toDateString()}
          </h4>
          <h5 style={{padding: '1.5rem'}}>
            <Icon className="padding-right-1" type="clock-circle" />
            {project.inspection.date.toDate().toTimeString()}
          </h5>
        </div>
      )}
      {project.inspection &&
      (project.inspection.inspector || {}).type !== 'field-user' &&
      auth.sunkaizenUser.organization.id === (project.inspection.inspector | {}).id ? (
        <div>
          <div className="padding-bottom-1">Select an inspector to continue with scheduling</div>
          <Select size="large" style={{width: '100%'}} onChange={onChange}>
            {inspectors
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((inspector, i) => (
                <Select.Option value={i}>{inspector.name}</Select.Option>
              ))}
          </Select>
        </div>
      ) : (
        <div className="flex-column full-width padding-top-1" style={{flex: 2, height: '40%'}}>
          <h3 style={{flex: 1}}>Assigned Inspector</h3>
          <InspectorBrief {...project.inspection.inspector} />
        </div>
      )}
    </div>
  )
}

const WrappedInspectionDate = subscribe()(InspectionDate)
export default WrappedInspectionDate

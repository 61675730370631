import React from 'react'

import {store} from './utilities'
import {Provider} from 'react-redux'
import DoIntake from './TopLevelState'

const EntryPoint = (props) => (
  <Provider store={store}>
    <DoIntake {...props} />
  </Provider>
)

export default EntryPoint

import React, {useState} from 'react'
import {Select} from 'antd'
import { db } from '../../../../utilities'
import { set } from 'lodash'

export default function AssignedTo({assignedTo, change, ticket, project}) {
  const [users, setUsers] = useState([])
  let rawUsers
  if (!project) {
    rawUsers = Object.values(ticket.watchersMap)
  } else {
    rawUsers = [
      {...project.installer, type: 'Installer'},
      {...project.client, type: 'Client'},
      {...project.reviewer, type: 'Quality Manager'},
      {...(project.inspection || {}).inspector, type: 'Inspector'},
    ]
  }

  const ids = rawUsers.map(({id}) => id)
    db.collection('users').where('id', 'in', ids).get().then((querySnapshot) => {
      const disabledIds = [];
      
      querySnapshot.forEach((doc) => {
          const { disabled = false } = doc.data();
          if (disabled) {
              disabledIds.push(doc.id);
          }
      });
      const tmp = rawUsers.filter(user => !disabledIds.includes(user.id));
      setUsers(tmp);
  }).catch(error => {
      console.error("Error fetching users: ", error);
  });
 


  return (
    <div>
      <label>Assigned To</label>
      <Select
        className="input-field"
        size="large"
        value={assignedTo}
        placeholder="Select Assignee or enter Email"
        onChange={(e) => change('assignee', e)}
        style={{width: `100%`}}
      >
        {users
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map(({name, id, type}) => (
            <Select.Option key={id} value={id}>{`${name} (${type})`}</Select.Option>
          ))}
        <Select.Option key={`Other`} value={`Other`}>
          Other
        </Select.Option>
      </Select>
    </div>
  )
}

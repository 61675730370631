import React from 'react'
import {Select, Icon, Tooltip} from 'antd' //	Input,
import PhotoRequired from './PhotoRequired'
import Followup from './Followup'
import {connect} from 'react-redux'
import {setField} from '../../action_creators'

const stop = (state, ownProps) => ({
  field: state.fields[ownProps.id],
  value: state.fields[ownProps.id].value,
})
const dtop = (dispatch) => ({setField: ({id, value}) => dispatch(setField(id, value))})

export function Dropdown({
  setField,
  field,
  id,
  question,
  label,
  options=[],
  required,
  tipText,
  value = {},
  photoRequired,
}) {
  let {values = {}, followup = {}} = value || {}
  const ff = {}
  options.forEach((option) => (ff[option.label] = {}))
  followup = {...ff, ...followup}

  const set = (v) => {
    field.value = {values: {[v]: true}, followup}
    setField({id, value: field})
  }

  const [currentValue] = Object.keys(values)
  const [selectedOption] = options.filter((option) => option.label === currentValue)

  return (
    <div>
      {required && (
        <div className="bold flex-row">
          <b>
            <Tooltip title="Required">
              <Icon type="exclamation-circle" className="padding-right-1" style={{fontSize: `2rem`}} />
            </Tooltip>
          </b>
          <div>REQUIRED</div>
        </div>
      )}
      <div className="bold padding-bottom-1 linebreak">{question}</div>
      <div className="flex-column full-width align-start">
        <div className="padding-right-1 linebreak" style={{fontFamily: `Arial, serif`, fontSize: `85%`}}>
          {label ? label.toUpperCase() : `no label`}
        </div>
        <div className="full-width">
          <Select style={{width: `100%`}} placeholder={''} onChange={set} value={currentValue}>
            {options.map((option, i) => (
              <Select.Option key={i} value={option.label}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
          <Followup id={id} option={selectedOption} />
        </div>
        <div className="linebreak">
          <small>{tipText && `${tipText}`}</small>
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  )
}

const WrappedDropdown = connect(stop, dtop)(Dropdown)
export default WrappedDropdown

import React from 'react'

// this component will display label and user entry. used in actions for action strategy information and etc..
const InputCtrlAction = ({name, handleChange, uniqueValue, defaultValue}) => {
  return (
    <React.Fragment>
      <label className="padding-right-10 actionStrategyLabel">{name}<span style={{color:'red'}}>*</span></label>
      <input
        className="ant-input ant-input-lg actionStrategyInput"
        key={`input-${uniqueValue}-${defaultValue}`}
        id={`input-${uniqueValue}-${defaultValue}`}
        name={`input-${uniqueValue}-${defaultValue}`}
        maxLength={500}
        placeholder={`please enter ${name}`}
        defaultValue={defaultValue}
        onBlur={handleChange}
        type="text"
      />
    </React.Fragment>
  )
}

export default InputCtrlAction

import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {Spin, Divider} from 'antd' //	, Button
import Details from './Details'
import PhotoPool from './PhotoPool'
import {PhotoViewer} from './PhotoViewer'
import {DragDropContext} from 'react-beautiful-dnd'
import Sections from './Sections'
import {setProject, setField, onDragStart, photoViewer} from './action_creators'
import {dropHandle} from './utilities'
import {connect} from 'react-redux'
import {saveImages} from './utilities'
import AutoRubricQuestion from './AutoRubricQuestion'
import SystemLogger from '../SystemLogger'
import { auth } from '../Auth'

const mapStateToProps = (state) => ({
  project: state.project,
  photos: (state.fields[state.project.id] || {}).images,
  loading: state.status.loading,
  open: state.meta.photoViewer,
  fields: state.fields,
})

const mapDispatchToProps = (dispatch) => ({
  setProject: (project) => dispatch(setProject(project)),
  onDragStart: () => dispatch(onDragStart(true)),
  close: () => dispatch(photoViewer(false)),
  setField: (id, value) => dispatch(setField(id, value)),
})

function DnDWrapper({project, fields, setField, photos = {}, loading, open, close, onDragStart}) {
  const didMountRef = useRef(false)

  useEffect(() => {
    // save photos
    if (didMountRef.current && !open) {
      console.info('Processed Photos:', photos)
      saveImages()
    }
    didMountRef.current = true
  }, [open])

  const done = async (photos) => {
    for (let k in photos) {
      const photo = photos[k]
      if (photo.delete) {
        delete photos[k]
      }
    }
    console.info('Done - ', photos)
    await setField(project.id, {images: photos})
    close()
  }

  const showAutoRubric = project.rubric === 'Auto-Rubric' || (project.rubric && project.rubric.isAutoRubricSelected)

  const autoRubricData = 
    project &&
    project.formRubrics &&
    Object.keys(project.formRubrics).length > 0 &&
    project.formRubrics[Object.keys(project.formRubrics)[0]] &&
    project.formRubrics[Object.keys(project.formRubrics)[0]].autoRubricData

  const handleUpdatedAutoRubric = (e) => {
    try {
      const selectedValue = e.target.value && e.target.value.split('-')[0]
      const value = autoRubricData.options.find((x) => x.selectedRubric === selectedValue)
      const selectedRubric = value && value.selectedRubric
      if (selectedRubric) {
        const rubricId = Object.keys(project.formRubrics).find(
          (rec) => project.formRubrics[rec].name === selectedRubric
        )
        project.formRubrics[rubricId].isAutoRubricSelected = true
        project.formRubrics[rubricId].selectedOption =
          e.target.value && e.target.value.split('-').length > 0 && e.target.value.split('-')[1]
        project.rubric = project.formRubrics[rubricId]
        setProject(project)
      }
    } catch(err) {
      console.log('exception while selecting auto rubric')
      const logger = new SystemLogger(window.location.pathname, auth.sunkaizenUser.id)
      logger.log(`exception while selecting auto rubric and error message is ${err.message}`)
    }
  }
  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={dropHandle}>
      <div>
        {showAutoRubric && (
          <div className="background-white" style={{marginBottom: -15, paddingTop: 10 }}>
           
            <AutoRubricQuestion
              key={project.rubric && project.rubric.selectedValue}
              {...autoRubricData}
              handleUpdatedAutoRubric={handleUpdatedAutoRubric}
              selectedValue={project.rubric && project.rubric}
            />
            <Divider />
          </div>
        )}
        <div className="flex-row" style={{ height: '100vh', overflow: 'hidden' }}>
          <div className="padding-2 background-white flex-row" style={{flex: 3, width: '65%', overflowY: 'scroll' }}>
            {project.intake && project.intake.order && (
              <div className="full-width">{loading ? <Spin /> : <Sections />}</div>
            )}
          </div>
          <div className="flex-column padding-left-1" style={{flex: 2, overflowY: 'scroll' }}>
            <Details />
            <PhotoPool />
          </div>
          {open && <PhotoViewer {...{photos, done}} />}
        </div>
      </div>
    </DragDropContext>
  )
}

DnDWrapper.propTypes = {
  project: PropTypes.object,
  photos: PropTypes.object,
  loading: PropTypes.bool,
}

const WrappedDnDWrapper = connect(mapStateToProps, mapDispatchToProps)(DnDWrapper)
export default WrappedDnDWrapper

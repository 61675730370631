import React from 'react'

import {Checkbox, Icon, Tooltip} from 'antd' //	Input,
import PhotoRequired from './PhotoRequired'
import Followup from './Followup'

import {connect} from 'react-redux'
import {setField} from '../../action_creators'

const stop = (state, ownProps) => ({
  field: state.fields[ownProps.id],
  value: state.fields[ownProps.id].value,
})
const dtop = (dispatch) => ({setField: ({id, value}) => dispatch(setField(id, value))})

// question, label, options, required, tipText, value, photoRequired,
export function Checkboxes({id, setField, field}) {
  function set(v) {
    const vv = {}
    v.forEach((value) => (vv[value] = true))
    field.value = {values: {...vv}, followup}
    setField({id, value: field})
  }

  let {value, options = [], required, question, label, photoRequired, tipText} = field
  let {values = {}, followup = {}} = value || {}

  const currentValues = Object.keys(values)

  return (
    <div>
      {required && (
        <div className="bold flex-row">
          <Tooltip title="Required">
            <Icon type="exclamation-circle" className="padding-right-1" style={{fontSize: '2rem'}} />
          </Tooltip>
          <div>REQUIRED</div>
        </div>
      )}
      <div className="bold padding-bottom-1 linebreak">{question}</div>
      <div className="flex-column full-width align-start">
        <div className="padding-right-1 linebreak" style={{fontFamily: 'Arial, serif', fontSize: '85%'}}>
          {(label || '').toUpperCase()}
        </div>
        {/* <div className="linebreak">{tipText && `(${tipText})`}</div> */}
        <div className="padding-1 full-width" style={{border: '0.1rem solid #C4CDD5'}}>
          <Checkbox.Group options={options.map((option) => option.label)} value={currentValues} onChange={set} />
          {options.map((option) => values[option.label] && <Followup id={id} option={option} />)}
        </div>
        <div className="linebreak">
          <small>{tipText && `${tipText}`}</small>
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  )
}

const WrappedCheckboxes = connect(stop, dtop)(Checkboxes)
export default WrappedCheckboxes

import React, {useState, useEffect} from 'react'
import {saveImages} from './utilities'
import DnDWrapper from './DnDWrapper'
import {Spin} from 'antd'
import {resetState} from './action_creators'
import {connect} from 'react-redux'
import {subscribe} from '../HOC/Firebase/SubscribeHOC'
import {useLoggerEffect, getLock} from '../effects'
import useProcessProject from './useProcessProject'

const stop = (state) => ({loading: state.status.loading})
const dtop = {resetState}

function DoIntake({match, loading, resetState, makeSubscription, subscribeResources}) {
  getLock(match.params.id)
  const logger = useLoggerEffect(`/projects/${match.params.id}`)
  const readonly = useProcessProject(logger, subscribeResources)

  useEffect(function () {
    makeSubscription({paths: [{collection: 'projects', doc: match.params.id}]}, 'project')
    window.addEventListener('beforeunload', saveImages)
    return () => {
      window.removeEventListener('beforeunload', saveImages)
      resetState()
    }
  }, [])

  return (
    <div>
      {loading && <Spin size="large" />}
      <div className="padding-2">
        {readonly ? (
          <h1 className="page-header">This project is in review. You cannot modify it.</h1>
        ) : (
          <div className="flex-column" style={{textAlign: 'left'}}>
            <h1 className="page-header bold padding-bottom-2" style={{flex: 1}}>
              Intake
            </h1>
            <DnDWrapper />
          </div>
        )}
      </div>
    </div>
  )
}

const WrappedDoIntake = subscribe()(connect(stop, dtop)(DoIntake))
export default WrappedDoIntake

import {store} from './utilities'

export const deleteField = (field, key) => ({type: `delete${field}`, key})
export const category = (type) => (value) => ({type, value})
export const activate = (key, value) => ({type: 'active', key, value})
export const edit = (value) => ({type: 'edit', value})
export const loadSection = (value) => ({type: 'loadsection', value})
export const modified = (bool) => ({type: 'modified', value: bool})
export const fieldDependency  = (value) => {
  return ({ type: 'fieldDependency', value})
}

export const setField = (type) => (key, value) => ({type, key, value})
export const modify = (type) => (k, v) => {
  store.dispatch(modified(true))
  return setField(type)(k, v)
}

const c = () => ({type: 'clear'})

export const clear = () => {
  store.dispatch(modified(false))
  return c()
}

function makeTimeFilter(type, instances) {
  if (!instances.before && !instances.after) return

  const key = `${type}._seconds`
  const filter = {[key]: {}}
  if (instances.after) {
    filter[key].gte = instances.after.startOf('day').unix()
  }
  if (instances.before) {
    filter[key].lte = instances.before.endOf('day').unix()
  }

  return {range: filter}
}

const filterType = {
  type: (instances) => ({terms: {'intake.title.sortable': instances}}),
  status: (instances) => ({terms: {'status.sortable': instances}}),
  client: (instances) => ({terms: {'client.id': instances}}),
  'quality-manager': (instances) => ({terms: {'reviewer.name.sortable': instances}}),
  installer: (instances) => ({terms: {'installer.name.sortable': instances}}),
  completed: ([instances]) => makeTimeFilter('completed_on', instances),
  started: ([instances]) => makeTimeFilter('created', instances),
  outcome: () => null,
}

export default function applyFilter(filter) {
  const ff = []
  const qs = []

  for (let f in filter) {
    if (f !== 'completed' && f !== 'started') continue
    if (!filter[f].instances.includes('all')) {
      ff.push(filterType[filter[f].type](filter[f].instances))
    }
    delete filter[f]
  }

  for (let f in filter) {
    if (!filter[f].instances.includes('all')) {
      // this is a hack until I can figure out why the status is different in the elastic index -- Chris
      if (filter[f].type === 'status' && filter[f].instances.includes('Cancelled')) {
        const i = filter[f].instances.indexOf('Cancelled')
        filter[f].instances.splice(i, 1, 'Canceled')
      }

      qs.push(filterType[filter[f].type](filter[f].instances))
    }
  }

  return {filters: ff.filter((i) => i), query_strings: qs}
}

import {store} from '../utilities'
import {getLock, unlock} from './locking'
import {lockQueue, setLoading} from '../action_creators'
import get from './get'
import makeQuery from './makeQuery'
import preProcess from './preProcess'
import postProcess from './postProcess'
import {FULL_TEXT_SEARCH_URL} from '../../config'

import { auth } from '../../Auth'

export default async function query() {
  const state = store.getState()
  const lock = getLock()

  if (state.loading) {
    return
  }

  if (!Object.keys(state.resources).length) {
    store.dispatch(setLoading(true))
  }

  try {
    const query = makeQuery()
    preProcess(query)

    if(query.type === "reports"){
      if(auth.sunkaizenUser.type === 'field-user' || auth.sunkaizenUser.type === 'quality-manager') {
        if (auth.sunkaizenUser.type === 'field-user') {
          query.query = {
            "match": { 
              "inspection.inspector.id": auth.sunkaizenUser.id
          }
          }
        } 
        else if (auth.sunkaizenUser.type === 'quality-manager') {
          query.query = {
            "match": { 
              "reviewer.id": auth.sunkaizenUser.id
          }
          }
        } 
        query.sort =  {
          "created._seconds": {
            "order": "desc"
          }
        }
        query.terms = 'reportsApi'
      }  
    }

    const url = `${FULL_TEXT_SEARCH_URL}/full_search`
    const res = await get(url, query)
    //console.log('/full_search', query, 'Result', res)

    await postProcess(res, lock)
  } catch (e) {
    console.log('error getting resources from elastic', e)
  } finally {
    if (state.loading) store.dispatch(setLoading(false))
    if (unlock(lock)) {
      store.dispatch(lockQueue(false))
      // queue()
    }
  }
}

import React from 'react'

// this component will render label and multi input. used in actions page like reference information and etc..
const MultiInputControlAction = ({
  name,
  handleChange,
  uniqueValue,
  defaultValue,
  isReq = false,
  shortNotes = '',
}) => {
  return (
    <React.Fragment>
      <div className="actionDesc">
        <label className="padding-right-30 actionDescLabel" htmlFor="actionDesc">
          {name} {isReq && <span style={{color: 'red'}}>*</span>}
        </label>
      </div>
      <div>
        <textarea
          className="ant-input ant-input-lg actionDescValue"
          key={`strategy-${uniqueValue}-${defaultValue}`}
          defaultValue={defaultValue}
          placeholder={`Please enter ${name}`}
          onBlur={handleChange}
        />
      </div>
      
      {shortNotes && (
        <div style={{ marginTop: 10 }}>
          {` Note: Enter Urls in the format like `} 
          <span style={{ fontWeight: 600}}>[link text](url)
          </span> {`and use pipe symbol `}  <span style={{ fontWeight: 600}}> {`|`} </span> 
          {` for multiple urls. eg:`}
          <span style={{marginLeft: 10, fontWeight: 600}}> {shortNotes}</span>
        </div>
      )}
      
    </React.Fragment>
  )
}

export default MultiInputControlAction

import React, {useEffect, useState} from 'react'
import {Button} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import ActionsRender from './ActionsRender'
import DB from '../DAL/DB'
import save from './save'


// User can create actions managed at this component
const ActionsReq = () => {
  const {actionList: actionListDB} = useSelector((state) => state.actionsState)
  const {saveEnterprisePDFOptionChecked} = useSelector((state) => state.form)
  const [actionsCount, setActionsCount] = useState(actionListDB ? actionListDB : [])
  const [actionList, setActionList] = useState(actionListDB || [])
  const dispatch = useDispatch()
  const form = useSelector((state) => state.form)
  const formAction = new DB.FormAction(form.id)
  const [enableSave, setEnableSave] = useState(false)

  const actionObj = {
    id: '',
    strategy: '',
    criticality: '',
    cost: '',
    difficulty: '',
    icon: '',
    actionDescription: '',
    referenceInformation: '',
    tags: [],
  }

  useEffect(() => {
    dispatch({type: 'saveAction', value: actionList})
  },[JSON.stringify(actionList)])

  const handleDeleteAction = (action) => {
    const editActions = [...actionList]
    const editedRec = editActions.find((x) => x.id === action.id)
    editedRec.isDelete = 'confirmation'
    setActionList(editActions)
    handleSaveReduxState(editActions)
  }

  const handleDeleteActionCancel = (action) => {
    const editActions = [...actionList]
    const editedRec = editActions.find((x) => x.isDelete === 'confirmation')
    editedRec.isDelete = false
    setActionList(editActions)
    handleSaveReduxState(editActions)
  }

  const handleDeleteActionConfirmation = (action) => {
    const editActions = [...actionList]
    const editedRec = editActions.find((x) => x.isDelete === 'confirmation')
    editedRec.isDelete = true
    setActionList(editActions)
    handleSaveReduxState(editActions)
  }

  const handleAddActions = () => {
    const actionListUpdated = [...actionList]
    actionObj.id = formAction.makeId()
    actionListUpdated.push(actionObj)
    setActionList(actionListUpdated)
    handleSaveReduxState(actionListUpdated)
  }

  const handleUpdateAction = (updatedActionObj) => {
    const actionListUpdated = [...actionList]
    const objIndex = actionListUpdated.findIndex((x) => x.id == updatedActionObj.id)
    actionListUpdated[objIndex] = updatedActionObj
    setActionList(actionListUpdated)
    handleSaveReduxState(actionListUpdated)
  }

  const handleSaveReduxState = async(actionListUpdated) => {
    actionListUpdated.filter(x => x.isDelete).forEach((rec, index) => {
      rec.actionIndex = 0
    })
    actionListUpdated.filter(x => !x.isDelete).forEach((rec, index) => {
        rec.actionIndex = index + 1
    })
    setTimeout(() => {
      dispatch({type: 'saveAction', value: actionListUpdated})
      setEnableSave(true)
    }, 10)
  }

  const handleSaveActions = () => {
    // calling save method to save into Firebase DB
    save()
    setEnableSave(false)
  }

  const isSaveEnabled = () => {
    let isEmpty = false;
    // looping all actions
    (actionList || [])
      .filter((x) => x.isDelete !== true).forEach(actionObj => {
       ["actionDescription", "strategy"].forEach(prop => {
          //checking above two action properties are empty or not
          if (actionObj[prop] === "") {
            isEmpty = true;
          }
        })
      })
    return isEmpty;
  }

  const renderActions = () => {
    return (
      <ActionsRender
        actionList={actionList}
        handleDeleteAction={(rec) => handleDeleteAction(rec)}
        handleDeleteActionCancel={(rec) => handleDeleteActionCancel(rec)}
        handleDeleteActionConfirmation={(rec) => handleDeleteActionConfirmation(rec)}
        handleUpdateAction={(rec, updatedActionObj) => handleUpdateAction(rec, updatedActionObj)}
      />
    )
  }

  const renderActionbuttons = () => {
    return (
      <React.Fragment>
        <Button className="formAction" title="add-tag" icon="plus" onClick={() => handleAddActions()} />
        <Button
          ghost={false}
          className="thick saveActionbtn"
          type={`primary`}
          size="large"
          disabled={actionList.length === 0 || !enableSave || isSaveEnabled()}
          onClick={() => handleSaveActions()}
        >
          Save
        </Button>
      </React.Fragment>
    )
  }

  const handleGeneratePDFSelection = (e) => {
    dispatch({type: 'saveEnterprisePDFOption', value: e.target.checked})
    setEnableSave(true)
  }

  return (
    <div>
      {renderActionbuttons()}
      <div>
        <div className='generatePDF'>
          <input type="checkbox"
              key={`chk-${saveEnterprisePDFOptionChecked}`}
              defaultChecked={saveEnterprisePDFOptionChecked} 
              onChange={handleGeneratePDFSelection}/>
          <label className='generatePDFLabel'>Generate Action Report for all Projects using this Form</label>
        </div>
        <h3> Available Actions</h3>{' '}
      </div>
      <React.Fragment key={`action-${actionsCount.length}`}>{renderActions()}</React.Fragment>
    </div>
  )
}

export {ActionsReq}

import {store} from './store'
import DB from '../../DAL/DB'

const projects = new DB.Projects()

export default async function saveImages() {
  const state = store.getState()
  const {project, fields} = state
  console.info('saveImages', fields[project.id].images)

  project.photos = fields[project.id].images || {}

  try {
    await projects.update(
      project.id,
      {
        noMerge: true,
      },
      project
    )
  } catch (e) {
    console.log(e)
    throw e
  }
}

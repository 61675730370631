import React from 'react'
import {Tabs} from 'antd'
import {StickyContainer} from 'react-sticky'
import Section from './Section'
import {connect} from 'react-redux'
import {setActive, highlightErrors} from './action_creators'
import {TabBar} from './SectionsTabBar'

const mapStateToProps = (state) => ({
  active: state.active.section,
  project: state.project,
})

const mapDispatchToProps = (dispatch) => ({
  setActive: (e) => {
    dispatch(setActive({name: `section`, value: e}))
  },
  highlightErrors: (errors) => dispatch(highlightErrors(errors || {})),
})

export function Sections({active, setActive, project, highlightErrors}) {
  return (
    <StickyContainer data-cy="intakeSections">
      <Tabs
        renderTabBar={(props, Default) => <TabBar {...{props, Default, highlightErrors, project}} />}
        activeKey={active}
        defaultActiveKey="0"
        onChange={setActive}
      >
        {project.intake.order.map((k) => (
          <Tabs.TabPane key={k} tab={project.intake.sections[k].title}>
            <Section section={project.intake.sections[k]} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </StickyContainer>
  )
}

const WrappedSections = connect(mapStateToProps, mapDispatchToProps)(Sections)
export default WrappedSections

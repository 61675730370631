import React, {useState, useEffect} from 'react'
import {Input, Button} from 'antd'
// import {CheckOutlined} from "@ant-design/icons"
import {connect} from 'react-redux'
import {modify} from './action_creators'

const stop = (state) => {
  const {title, created, updated} = state.form
  return {title, created, updated}
}

const formModify = modify('form_field')
const dtop = {formModify}

export function FormSettings({title, created, updated, formModify, handleTitleUpdate}) {
  const [editing, setEditing] = useState(false)
  const [eTitle, setETitle] = useState(title)

  useEffect(
    function () {
      if (eTitle !== title) formModify('title', eTitle)
    },
    [editing]
  )

  //Prevent bug where first set on the title set form to 'modified' state
  useEffect(() => {
    setETitle(title)
    handleTitleUpdate(title)
  }, [title])

  return (
    <div className="form-settings-container padding-1">
      <div className="flex-row align-center">
        <div className="margin-right-2 flex-row">
          {editing ? (
            <Input
              className="margin-bottom-1"
              size="large"
              value={eTitle}
              style={{marginRight: '1rem'}}
              onChange={(e) => setETitle(e.target.value)}
              data-cy="formInput"
            />
          ) : (
            <h2>{title}</h2>
          )}
          <Button
            style={{marginLeft: '1rem'}}
            title="edit"
            icon={editing ? 'check' : 'edit'}
            onClick={() => setEditing(!editing)}
            data-cy="toggleEditFormBtn"
          />
        </div>
        <div>
          {created && (
            <div className="flex-row">
              <div className="bold padding-right-1">Created</div>
              <div>{created.toDate().toLocaleString()}</div>
            </div>
          )}
          {updated && (
            <div className="flex-row">
              <div className="bold padding-right-1">Updated</div>
              <div>{updated.toDate().toLocaleString()}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const WrappedFormSettings = connect(stop, dtop)(FormSettings)
export default WrappedFormSettings

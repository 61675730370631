import {firebase} from '../../utilities'
import DB from '../../DAL/DB'

const projects = new DB.Projects()

export function initializeProject(project) {
  project.startedAt = firebase.firestore.Timestamp.now()
  project.status = `In Progress`
  return projects.update(project.id, null, project)
}

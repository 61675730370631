import React from 'react'
import {Input} from 'antd' //	, Checkbox, Icon, Tooltip
import {setFollowup} from '../../action_creators'
import {connect} from 'react-redux'
import RequireFollowUpPhoto from './RequireFollowUpPhoto'

// const RequireSignature = ({setF, followup}) => (
//   <div className='flex-row padding-0-5 align-center' >
//     <div className='bold padding-right-1' >Signature</div>
//     <div style={{border: `0.1rem solid lightgrey`, borderRadius: `0.5rem`}}>
//       <Input.TextArea
//         size="medium"
//         placeholder="Signature Required"
//         value={followup[`requireFollowupSignature`]}
//         onChange={f => setF(`requireFollowupSignature`, f.target.value)}
//       />
//     </div>
//   </div>
// )

const RequireFollowUpComment = ({setF, followup}) => (
  <div className="flex-row padding-0-5 align-center">
    <div className="bold padding-right-1">Comment</div>
    <div style={{border: `0.1rem solid lightgrey`, borderRadius: `0.5rem`}}>
      <Input.TextArea
        size="medium"
        placeholder="Comment Required"
        value={followup[`requireFollowUpComment`]}
        onChange={(f) => setF(`requireFollowUpComment`, f.target.value)}
      />
    </div>
  </div>
)

const followupComponents = {
  requireFollowupComments: RequireFollowUpComment,
  // requireFollowupSignature: RequireSignature,
  requireFollowupPhoto: RequireFollowUpPhoto,
}

const stop = (state, ownProps) => ({
  field: state.fields[ownProps.id],
  followup:
    state.fields[ownProps.id].value && state.fields[ownProps.id].value.followup
      ? state.fields[ownProps.id].value.followup[(ownProps.option || {}).label]
      : {},
})
const dtop = (dispatch) => ({
  setFollowup: (id) => (value) => (type, followup) => dispatch(setFollowup({id, value, type, followup})),
})

export function Followup({id, field, option = {}, setFollowup}) {
  const setF = setFollowup(id)(option.label)

  return (
    <div>
      {Object.keys(followupComponents).some((ff) => option[ff]) && (
        <div className="padding-1" style={{backgroundColor: `#FAE2FE`, borderRadius: `0.3rem`}}>
          <h3 className="padding-top-1">Followup Information is Necessary for Option ({option.label})</h3>
          {Object.keys(followupComponents).map((ff) => {
            const C = option && option[ff] ? followupComponents[ff] : () => <div></div>

            if (field.value && !field.value.followup[option.label]) field.value.followup[option.label] = {}
            return (
              <div>
                <C
                  {...{option, id, setF}}
                  followup={field.value && field.value.followup ? field.value.followup[option.label] : {}}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const WrappedFollowup = connect(stop, dtop)(Followup)
export default WrappedFollowup

import {store} from './utilities'

export const setProject = (project) => ({type: 'SET_PROJECT', project})
export const setFields = (fields) => ({type: 'SET_FIELDS', fields})
export const setField = (id, value) => ({type: 'SET_FIELD', id, value})
export const setSection = (section) => ({type: 'SET_SECTION', section})
export const setCorrection = (id, correction = undefined) => ({type: 'SET_CORRECTION', id, correction})
export const setActive = ({name, value}) => ({type: 'SET_ACTIVE', name, value})
export const setStatus = (status) => ({type: 'SET_STATUS', status})
export const setLoading = (loading) => ({type: 'SET_LOADING', loading})
export const photoViewer = (value) => ({type: 'PHOTO_VIEWER', value})
export const reviewMode = (value) => ({type: 'REVIEW_MODE', value})
export const resetState = () => ({type: 'RESET'})
export const highlightErrors = (errors) => ({type: 'HIGHLIGHT_ERRORS', errors})
export const drag = (dragging) => ({type: 'DRAGGING', dragging})
export const onDragStart = (dragging) => ({type: 'DRAGGING', dragging})
export const updateFieldsState = (value) => ({ type: 'UPDATE_FIELDS_VALUES_AUTORUBRIC', value})

export const deletePhoto = (name, id) => {
  const state = store.getState()
  const field = {...state.fields[id]}
  delete field.images[name]
  return setField(id, field)
}

export const setFollowup = ({id, value, type, followup}) => {
  const state = store.getState()

  const field = state.fields[id]
  if (!field.value.followup) field.value.followup = {[value]: {[type]: followup}}
  else {
    if (field.value.followup[value]) field.value.followup[value] = {...field.value.followup[value], [type]: followup}
    else field.value.followup[value] = {[type]: followup}
  }

  return setField(id, field)
}

import React from 'react'
import {TimePicker, Icon, Tooltip} from 'antd' //	Input, Checkbox,
import PhotoRequired from './PhotoRequired'
import {firebase} from '../../../utilities'
import {setField} from '../../action_creators'
import {connect} from 'react-redux'
import moment from 'moment'

let now = moment()

const stop = (state, ownProps) => ({
  field: state.fields[ownProps.id],
  value: state.fields[ownProps.id].value,
})
const dtop = (dispatch) => ({setField: ({id, value}) => dispatch(setField(id, value))})

export function TimeInput({setField, field, id}) {
  const {value, question, label, required, photoRequired, tipText} = field
  let val = value && Object.keys(value).length > 0 && value.toDate ? moment(value.toDate()) : null
  const set = (value) => {
    field.value = firebase.firestore.Timestamp.fromDate(value.toDate())
    setField({id, value: field})
  }

  return (
    <div>
      {required && (
        <div className="bold flex-row">
          <Tooltip title="Required">
            <Icon type="exclamation-circle" style={{fontSize: `2rem`, padding: `0 1rem 0 0`}} />
          </Tooltip>
          <div>REQUIRED</div>
        </div>
      )}
      <div className="bold padding-bottom-1 linebreak">{question}</div>
      <div className="flex-column full-width align-start">
        <div className="padding-right-1 linebreak" style={{fontFamily: `Arial, serif`, fontSize: `85%`}}>
          {(label || ``).toUpperCase()}
        </div>
        {/* <div className='linebreak'>{tipText && `(${tipText})`}</div> */}
        <div className="full-width">
          <TimePicker style={{width: `100%`}} value={val || now} onChange={set} />
        </div>
        <div className="linebreak">
          <small>{tipText && `${tipText}`}</small>
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  )
}

const WrappedTimeInput = connect(stop, dtop)(TimeInput)
export default WrappedTimeInput

import React from 'react'
import {Button} from 'antd'
import {Sticky} from 'react-sticky'
import {history} from '../history'
import {saveIntake} from './utilities'

export function TabBar({project, highlightErrors, props, Default}) {
  async function saver(route) {
    try {
      await saveIntake()
      if (route) history.push(route)
    } catch (e) {
      highlightErrors(e)
      console.log(`some errors occurred that prevent submission`, e)
    }
  }

  return (
    <Sticky>
      {({style}) => (
        <div className="flex-row background-white justify-space-between" style={{...style, zIndex: 10}}>
          <Default {...props} style={{width: '75%'}} />
          <div className="flex-row" style={{flex: 1}}>
            <Button style={{margin: `1rem 1rem 1rem 0`}} type="default" onClick={() => saver()}>
              Save
            </Button>
            <Button
              style={{margin: `1rem 0 1rem 0`}}
              type="primary"
              onClick={() => saver(`/projects/${project.id}/manage`)}
            >
              Save and Exit
            </Button>
          </div>
        </div>
      )}
    </Sticky>
  )
}

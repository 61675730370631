const actionData = {
  costOptions: ['$', '$ - $$', '$ - $$$', '$ - $$$$', '$$ - $$$', '$$ - $$$$', '$$$ - $$$$', 'NA'],
  diffiOptions: ['Select Difficulty', 'Difficult', 'Moderate', 'Low', 'NA'],
  cricticalOptions: ['Select Criticality Rating', 'Critical', 'Moderate', 'Low', 'NA'],
  iconOptions: [
    'Select Icon Type',
    'Flooding',
    'Extreme Wind',
    'Extreme Heat',
    'Unhealthy Housing',
    'Operations and Community',
  ],
}

const dropdownData = [
  {
    name: 'Cost',
    options: actionData.costOptions,
    unquieProp: 'id',
    dbProp: 'cost',
  },
  {
    name: 'Difficulty',
    options: actionData.diffiOptions,
    unquieProp: 'id',
    dbProp: 'difficulty',
  },
  {
    name: 'Criticality',
    options: actionData.cricticalOptions,
    unquieProp: 'id',
    dbProp: 'criticality',
  },
  {
    name: 'Icon',
    options: actionData.iconOptions,
    unquieProp: 'id',
    dbProp: 'icon',
  },
]

const DEFAULT_TAB_ID = 1

export {actionData, dropdownData, DEFAULT_TAB_ID}

import React from 'react'
import {InputNumber, Icon, Tooltip} from 'antd'
import {connect} from 'react-redux'
import {setField} from '../../action_creators'
import PhotoRequired from './PhotoRequired'

const stop = (state, ownProps) => ({field: state.fields[ownProps.id]})
const dtop = (dispatch) => ({setField: ({id, value}) => dispatch(setField(id, value))})

export function NumericInput({setField, field, id, question, label, required, tipText, value = 0, photoRequired}) {
  const set = (number) => setField({id, value: {...field, value: number}})

  return (
    <div>
      {required && (
        <div className="bold flex-row">
          <Tooltip title="Required">
            <Icon type="exclamation-circle" style={{fontSize: '2rem', padding: '0 1rem 0 0'}} />
          </Tooltip>
          <div>REQUIRED</div>
        </div>
      )}
      <div className="bold padding-bottom-1 linebreak">{question}</div>
      <div className="flex-column full-width align-start">
        <div className="padding-right-1 linebreak" style={{fontFamily: `Arial, serif`, fontSize: `85%`}}>
          {(label || ``).toUpperCase()}
        </div>
        <div className="full-width">
          <InputNumber size="large" onChange={set} value={value} />
        </div>
        <div className="linebreak">
          <small>{tipText && `(${tipText})`}</small>
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  )
}

const WrappedNumericInput = connect(stop, dtop)(NumericInput)
export default WrappedNumericInput

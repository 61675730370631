import React from 'react'
import IntakeFailure from '../TicketBuilder/IntakeFailure'
import TicketModal from '../TicketBuilder'
import {CompletedWorkDetail, DetailsBox, TicketControls, TicketComments} from '.'
import {auth} from '../Auth'
import {useGetResource, useDBSubscribeOne} from '../effects'
import './styles.scss'
import {Card} from 'antd'
import DB from '../DAL/DB'

const tickets = new DB.Tickets()

export default function TopLevelState({match}) {
  const {ticketId: id} = match.params
  const ticket = useDBSubscribeOne(tickets, id) || {}
  const project = useGetResource('projects', (ticket || {}).project) || {}

  const {id: userId, type} = auth.sunkaizenUser
  const showTicketModal =
    [`admin`, `super-admin`].includes(type) ||
    (userId === ticket.creator && ![`Completed`, `Submitted`].includes(ticket.status))

  return (
    <div style={{textAlign: 'left'}} className="flex-column">
      <h1 className="flex-row align-center justify-space-between margin-2">
        <div data-cy="ticketDetailTitle">
          Ticket:&nbsp;<span style={{fontWeight: `400`}}>{ticket.title}</span>
        </div>
        {showTicketModal && <TicketModal mode="update" project={project} ticket={ticket} />}
      </h1>
      <div className="flex-row">
        <div className="margin-1" style={{flex: 1}}>
          <Card className="flex-column full-width" data-cy="ticketDetailCard">
            <h2 className="section-heading">Ticket Details</h2>
            <div className="subsection-title">Description</div>
            {ticket.description}
            <div className="subsection-title">Failure Details</div>
            <IntakeFailure enhanced={true} failure={ticket.failure} />
            <div className="subsection-title">Completed Work Details</div>
            <CompletedWorkDetail ticket={ticket} />
          </Card>
        </div>
        <div className="margin-1" style={{minWidth: '45rem'}}>
          <Card className="flex-column" data-cy="ticketStatusDetailCard">
            <DetailsBox ticket={ticket} />
            <TicketControls ticket={ticket} />
          </Card>
        </div>
      </div>
      <div className="margin-1">
        <Card>
          <TicketComments ticket={ticket} />
        </Card>
      </div>
    </div>
  )
}

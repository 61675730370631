import {combineReducers} from 'redux'

function form(state = {}, action) {
  switch (action.type) {
    case 'form_field':
      return {...state, [action.key]: action.value}
    case 'form':
      return action.value
    case 'clear':
      return {}
    case 'saveEnterprisePDFOption':
      return {...state, saveEnterprisePDFOptionChecked: action.value}
    default:
      return state
  }
}

function sections(state = {}, action) {
  switch (action.type) {
    case 'section':
      return {...state, [action.key]: action.value}
    case 'sections':
      return action.value
    case 'deletesection':
      const ff = {...state}
      delete ff[action.key]
      return ff
    case 'clear':
      return {}
    default:
      return state
  }
}

function fields(state = {}, action) {
  switch (action.type) {
    case 'field':
      return {...state, [action.key]: action.value}
    case 'fieldDependency':
      return {...state, state: action.value}
    case 'fields':
      return {...state, ...action.value}
    case 'deletefield':
      const ff = {...state}
      delete ff[action.key]
      return ff
    case 'clear':
      return {}
    default:
      return state
  }
}

function options(state = {}, action) {
  switch (action.type) {
    case 'option':
      return {...state, [action.key]: action.value}
    case 'options':
      return action.value
    case 'deleteoption':
      const ff = {...state}
      delete ff[action.key]
      return ff
    case 'clear':
      return {}
    default:
      return state
  }
}

function active(state = {section: null, field: null, option: null}, action) {
  switch (action.type) {
    case 'active':
      return {...state, [action.key]: action.value}
    case 'clear':
      return {section: null, field: null, option: null}
    default:
      return state
  }
}

function editing(state = null, action) {
  if (action.type === 'edit') {
    return action.value
  } else return state
}

function modified(state = false, action) {
  if (action.type === 'modified') return action.value
  else return state
}

// Holds data about what parts of the form have been
// loaded into local state from the DB
function loaded(state = {sections: []}, action) {
  if (action.type === 'loadsection') return {...state, sections: [...state.sections, action.value]}
  else return state
}

const actionList = []
function actionsState(state = {actionList: []}, action) {
  switch (action.type) {
    case 'saveAction':
      return {...state, actionList: action.value}
    default:
      return state
  }
}


function rubrics(state = {rubrics: []}, action) {
  switch (action.type) {
    case 'rubrics':
      return {...state, rubrics: action.value}
    default:
      return state
  }
}

const rootReducer = combineReducers({active, form, sections, fields, options, editing, modified, loaded, actionsState, rubrics})
export default rootReducer

import React, {useState} from 'react'
import {Tabs} from 'antd'
import Settings from './FormSettings'
import Logo from './Logo'
import DraggableTabs from './DraggableTabs'
import FieldsWrapper from './FieldsPanel'
import {ActionsReq} from './ActionsReq'
import {DEFAULT_TAB_ID} from './actionsDataJSON'

export default function FormEdit() {
  const [defaultTabId, setDefaultTabId] = useState(DEFAULT_TAB_ID)
  const [title, setTitle] = useState('')
  const {TabPane} = Tabs

  const handleTabChange = (tabId) => {
    setDefaultTabId(tabId)
  }

  const handleTitleUpdate = (title) => {
    setTitle(title)
  }

  return (
    <div className="padding-2 flex-column">
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab={title} key="1">
          <Settings handleTitleUpdate={handleTitleUpdate} />
          <Logo />
          <DraggableTabs />
          <FieldsWrapper />
        </TabPane>
        <TabPane tab="Actions" key="2">
          <ActionsReq />
        </TabPane>
      </Tabs>
    </div>
  )
}

import React, {useEffect, useState} from 'react'
import {history} from '../../history'
import {auth} from '../../Auth'
import {Button} from 'antd'
import {connect} from 'react-redux'
import {highlightErrors, reviewMode, setStatus, setLoading} from '../action_creators'
import {saveIntake} from '../utilities'
import {loggerEffect} from '../../effects'

import IntakeStatus from './IntakeStatus'
import ReviewOptions from './ReviewOptions'
import ActiveSectionDisplay from './ActiveSectionDisplay'
import {statusLogs} from './statusLogs'

const mapStateToProps = (state) => ({
  project: state.project,
  corrections: Object.values(state.project.intake.corrections || {}).filter((i) => i !== undefined).length,
  reviewMode: state.meta.reviewMode,
})

const mapDispatchToProps = (dispatch) => ({
  highlightErrors: (errors) => {
    dispatch(highlightErrors(errors))
  },
  toggleReviewMode: (bool) => dispatch(reviewMode(bool)),
  setStatus: (status) => dispatch(setStatus(status)),
  setLoading: (bool) => dispatch(setLoading(bool)),
})

export function Details({project, corrections, reviewMode, highlightErrors, setStatus, setLoading, toggleReviewMode}) {
  const [logger, setLogger] = useState(null)
  const [loadingTemp, setLoadingTemp] = useState(false)
  useEffect(loggerEffect(setLogger, `/projects/${project.id}`), [])

  async function finalize(done, status) {
    logger.categoryId = project.id

    console.info(`Finalize done:${done} status: ${status}`)
    try {
      if (!done && status === 'Review') {
        setStatus('Needs Corrections')
      }
      setLoadingTemp(true)
      // these functions will be called on projects Page now 

      await saveIntake(done)
      await statusLogs[status](logger, project.site.siteAddress, corrections)
      
      history.push('/projects')
      //history.push('/projects', { sourcePage: 'details', done }) 

    } catch (e) {
      setLoadingTemp(false)
      console.log(e)
      highlightErrors(e.errors)
      logger.event(
        `You must fill out all required fields to submit this project; missing fields have been highlighted`,
        null,
        2,
        {[auth.sunkaizenUser.id]: auth.sunkaizenUser.type}
      )
      return
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitReview = () => {
    if (project && project.rubric && project.rubric !== 'Auto-Rubric') {
      finalize(true, project.status)
    } else {
      logger.event(
        `You must fill out all required fields to submit this project; missing fields have been highlighted`,
        null,
        2,
        {[auth.sunkaizenUser.id]: auth.sunkaizenUser.type}
      )
    }
  }
  return (
    <div className="padding-bottom-1" data-cy="intakeDetails">
      <div className="background-white">
        <IntakeStatus status={project.status} />
        <ActiveSectionDisplay />
        <div
          className="flex-row justify-space-between"
          style={{borderBottom: '0.1rem solid #DFE3E8', padding: '1.5rem'}}
        >
          <div className="bold">Started</div>
          <div>{project.startedAt && project.startedAt.toDate().toDateString()}</div>
        </div>
        {!loadingTemp && (
        <div className="flex-row align-center justify-center padding-2">
          {(project.status === 'In Progress' || project.status === 'Needs Corrections') && (
            <Button onClick={() => handleSubmitReview()} type="primary">
              Submit for Review
            </Button>
          )}
          {project.status === 'Review' &&
            ['super-admin', 'admin', 'quality-manager', 'client'].includes(auth.sunkaizenUser.type) && (
              <ReviewOptions {...{reviewMode, toggleReviewMode, corrections, status: project.status, finalize}} />
            )}
        </div>
        )}
      </div>
    </div>
  )
}

const WrappedDetails = connect(mapStateToProps, mapDispatchToProps)(Details)
export default WrappedDetails

import {db, firebase} from '../utilities'
import Aggregations from './Aggregations'
import Analytics from './Analytics'
import Authorizations from './Authorizations'
import BaseModel from './BaseModel'
import Calendars from './Calendars'
import CompletedForms from './CompletedForms'
import CSVFilters from './CSVFilters'
import EmailLinks from './EmailLinks'
import FormCSV from './FormCSV'
import FormField from './FormField'
import Forms from './Forms'
import FormSection from './FormSection'
import FormAction from './FormAction'
import Logs from './Logs'
import Meta from './Meta'
import OauthClient from './OauthClient'
import OrganizationAssociations from './OrganizationAssociations'
import Organizations from './Organizations'
import ProjectLocks from './ProjectLocks'
import ProjectLogs from './ProjectLogs'
import Projects from './Projects'
import ProjectSnapshots from './ProjectSnapshots'
import QuestionScores from './QuestionScores'
import Reports from './Reports'
import RollingAverageScores from './RollingAverageScores'
import Rubrics from './Rubrics'
import ScoringSystems from './ScoringSystems'
import Sites from './Sites'
import Subscriptions from './Subscriptions'
import TempProjects from './TempProjects'
import TicketComments from './TicketComments'
import Tickets from './Tickets'
import UserCalendars from './UserCalendars'
import Users from './Users'

class Batch {
  operations: Promise<any>[]

  constructor() {
    this.operations = []
  }

  add(operation: Promise<any>) {
    this.operations.push(operation)
  }

  async commit() {
    try {
      const results = await Promise.all(this.operations)
      return results
    } catch (e) {
      console.log('batch commit failed: ', e)
      throw e
    }
  }
}

class FirebaseBatch {
  batch: firebase.firestore.WriteBatch
  constructor() {
    this.batch = db.batch()
  }

  update(baseQuery: BaseModel, id: string, data: firebase.firestore.DocumentData, merge: true) {
    const ref = baseQuery.baseQuery.doc(id)
    this.batch.set(ref, data, {merge})
  }

  delete(baseQuery: BaseModel, id: string) {
    const ref = baseQuery.baseQuery.doc(id)
    this.batch.delete(ref)
  }

  async commit() {
    this.batch.commit()
  }
}

export default {
  Batch,
  FirebaseBatch,
  Analytics,
  Aggregations,
  Meta,
  Projects,
  FormCSV,
  Forms,
  FormSection,
  FormAction,
  FormField,
  Users,
  Calendars,
  Reports,
  ProjectSnapshots,
  Rubrics,
  ScoringSystems,
  Sites,
  Subscriptions,
  ProjectLogs,
  Logs,
  Tickets,
  TicketComments,
  EmailLinks,
  Authorizations,
  Organizations,
  OrganizationAssociations,
  OauthClient,
  CSVFilters,
  QuestionScores,
  RollingAverageScores,
  TempProjects,
  ProjectLocks,
  UserCalendars,
  CompletedForms,
}

export const dbResources = {
  batch: Batch,
  analytics: Analytics,
  aggregations: Aggregations,
  projects: Projects,
  meta: Meta,
  formCSV: FormCSV,
  forms: Forms,
  formSection: FormSection,
  formAction: FormAction,
  formField: FormField,
  users: Users,
  calendars: Calendars,
  reports: Reports,
  snapshots: ProjectSnapshots,
  rolling_average_scores: RollingAverageScores,
  rubrics: Rubrics,
  scoring_systems: ScoringSystems,
  sites: Sites,
  subscriptions: Subscriptions,
  projectTransactionLog: ProjectLogs,
  transactionLog: Logs,
  tickets: Tickets,
  ticketComments: TicketComments,
  emailLinks: EmailLinks,
  authorizations: Authorizations,
  organizations: Organizations,
  oauth2_client: OauthClient,
  csvFilters: CSVFilters,
  tempProjects: TempProjects,
  project_locks: ProjectLocks,
  user_calendars: UserCalendars,
  completed_forms: CompletedForms,
}

import React from 'react'
import {DatePicker, Icon, Tooltip} from 'antd' //	Input, Checkbox,
import PhotoRequired from './PhotoRequired'
import {firebase} from '../../../utilities'
import {connect} from 'react-redux'
import {setField} from '../../action_creators'
import moment from 'moment'

const stop = (state, ownProps) => ({
  field: state.fields[ownProps.id],
  value: state.fields[ownProps.id].value,
})
const dtop = (dispatch) => ({setField: ({id, value}) => dispatch(setField(id, value))})

export function DateInput({setField, id, field}) {
  const {value, question, label, required, tipText, photoRequired} = field
  let val = value ? moment(value.toDate()) : null
  const set = (value) => {
    field.value = value ? firebase.firestore.Timestamp.fromDate(value.toDate()) : null
    setField({id, value: field})
  }

  return (
    <div>
      {required && (
        <div className="bold flex-row">
          <Tooltip title="Required">
            <Icon type="exclamation-circle" className="padding-right-1" style={{fontSize: `2rem`}} />
          </Tooltip>
          <div>REQUIRED</div>
        </div>
      )}
      <div className="bold padding-bottom-1 linebreak">{question}</div>
      <div className="flex-column full-width align-start">
        <div className="padding-right-1 linebreak" style={{fontFamily: `Arial, serif`, fontSize: `85%`}}>
          {(label || ``).toUpperCase()}
        </div>
        {/* <div className="linebreak">{tipText && `(${tipText})`}</div> */}
        <div className="full-width">
          <DatePicker className="full-width" value={val || null} onChange={set} />
        </div>
        <div className="linebreak">
          <small>{tipText && `${tipText}`}</small>
        </div>
      </div>
      {photoRequired && (!field.images || Object.keys(field.images).length < 1) && <PhotoRequired />}
    </div>
  )
}

const WrappedDateInput = connect(stop, dtop)(DateInput)
export default WrappedDateInput

import React from 'react'
import Field from './Field'

const Section = ({section}) => (
  <div>
    <h3 className="bold padding-bottom-2">{section && section.title}</h3>
    <div>{section && (section.order || []).map((fId, i) => <Field key={i} fieldId={fId} />)}</div>
    <hr />
  </div>
)

export default Section

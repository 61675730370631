import React from 'react'
import {auth} from '../../Auth'
import DB from '../../DAL/DB'

const projects = new DB.Projects()
const calendars = new DB.Calendars()

export default function ApproveCancelButton({project}) {
  const invalidUserType = !['client', 'admin', 'super-admin'].includes(auth.sunkaizenUser.type)
  const buttonClasses = `
      flex-row justify-center padding-0-5 margin-1 full-width color-white background-grey pointer cancel-button 
      ${invalidUserType ? 'background-secondary color-black' : ''}
    `

  if (invalidUserType) {
    return null
  }

  return (
    <div className="flex-row justify-end">
      <div
        className={buttonClasses}
        onClick={async(e) => {
          console.info('Approve Cancel!')
          projects.update(
            project.id,
            {
              noMerge: false,
            },
            {
              status: 'Canceled',
            }
          )
          try {
            const ok = await calendars.get(project.id, null)
            console.log('ok',ok)
            if(ok) {
              await calendars.update(project.id, null, {
                data: {...project , status: 'Canceled'}
              })
            }
          } catch (error) {
            console.log('error to update calendar',error)
          }
        }}
        disabled={invalidUserType}
      >
        Approve Cancel
      </div>
    </div>
  )
}
